export const WONoticeText = {
  loading: {
    title: "Work order is not available",
    explainer:
      "The system can not load the Work Order. Please try again later.",
  },
  saving: {
    title: "Work order saving error",
    explainer:
      "The system can not save the Work Order. Please try again later.",
  },
  sending: {
    title: "Move out notice sending error",
    explainer: "Your {Move out Notice} can not be sent, please try again.",
  },
  conflict: {
    title: "Work Order Draft Version conflict",
    explainer:
      "Another version of the Draft has been created recently. The existing edits will be discharged.",
  },
} as const;

export default WONoticeText;
