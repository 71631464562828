import React from "react";
import {
  AlertStatus,
  INoticeProps,
  Notice,
  Popup,
} from "@fyvedev/fe-storybook";
import {
  TWarningType,
  warningText,
} from "@/components/molecules/notifications";

interface WarningProps extends Omit<Partial<INoticeProps>, "hide"> {
  type: TWarningType;
  objectName?: string;
  attributes?: string[];
  time?: string;
  isOpen: boolean;
  onClose?: () => void;
}

const Warning: React.FC<WarningProps> = ({
  type,
  objectName,
  attributes,
  time,
  isOpen,
  infoBar,
  actions,
  onClose,
}) => {
  const warningPopupProps = warningText({
    warningType: type,
    objectName,
    attributes,
    time,
  });

  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <Notice
        defaultChildrenWrapper
        status={AlertStatus.DEFAULT}
        iconName="thunder-circle"
        iconFillClassName="fill-gold-500"
        infoBar={infoBar}
        alertHeading={warningPopupProps?.title}
        className="z-50"
        hide={onClose}
        actions={actions}
        size="s"
      >
        {warningPopupProps?.explainer}
      </Notice>
    </Popup>
  );
};

export default Warning;
