import React from "react";
import { NotFound } from "@fyvedev/fe-storybook";
import { useRouter } from "next/router";
import { NextPageWithLayout } from "../types/next-page-with-layout";
import Layout from "../components/layouts/AppLayout";
import { HeaderNav } from "../lib/const/common";

const NotFoundPage: NextPageWithLayout = () => {
  const router = useRouter();
  return (
    <div className="h-[calc(100vh-6rem)] mt-[6rem] p-4 sm:p-0">
      <NotFound onBackClick={async () => router.push("/app")} />
    </div>
  );
};

NotFoundPage.getLayout = (page) => (
  <Layout page={HeaderNav.FYVE}>{page}</Layout>
);

export default NotFoundPage;
