import { Warning } from "@/components/molecules/notifications";
import {
  DehydratedState,
  fromOneOf,
  LeaseControllerGetListParams,
  TicketStatus,
  useAuthControllerSignOut,
  useProfileControllerGetProfile,
} from "@fyvedev/client";
import { filterDuplicateClassNames, Header } from "@fyvedev/fe-storybook";
import {
  HeaderNav,
  HEADER_NAV_LIST,
  HEADER_NAV_LIST_MOBILE,
} from "lib/const/common";
import Routes from "lib/const/routes";
import { signOut } from "next-auth/react";
import { useRouter } from "next/router";
import React from "react";
import HeadTitle from "./HeadTitle";

interface HeaderProps extends React.ComponentProps<typeof Header> {}

interface LayoutProps
  extends React.HTMLProps<HTMLDivElement>,
    Partial<DehydratedState> {
  page: HeaderNav;
  onlyLogo?: boolean;
  withBackButton?: boolean;
  activeMenu?: HeaderNav;
}

export const leaseListParams: LeaseControllerGetListParams = {
  limit: 1,
  filter: {
    status: { $nin: [TicketStatus.canceled, TicketStatus.finished] },
  },
  sort: { startAt: -1 },
  populate: ["property"],
};

const extendMenu: HeaderProps["extendMenu"] = [
  {
    path: "profile",
    text: "Profile",
    iconName: "user",
  },
  {
    path: "logout",
    text: "Log out",
    iconName: "sign-out",
  },
];

const Layout: React.FC<LayoutProps> = (props) => {
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = React.useState(false);
  const [loadingLogOut, setLoadingLogOut] = React.useState(false);

  const {
    children,
    page,
    className: incomingClassName,
    onlyLogo,
    withBackButton,
    activeMenu,
  } = props;

  const router = useRouter();

  const { data: profile } = useProfileControllerGetProfile();

  const containerClassName = React.useMemo(() => {
    const defaultClassName = ["grid", "h-screen"];
    defaultClassName.push(
      "grid-rows-[6.125rem_calc(100vh-6.125rem)]",
      "sm:grid-rows-[6rem_calc(100vh-6rem)]"
    );

    return filterDuplicateClassNames(defaultClassName, incomingClassName);
  }, [incomingClassName]);

  const mutateSignOut = useAuthControllerSignOut({
    mutation: {
      onSuccess: () => {
        void signOut({ redirect: true });
        setLoadingLogOut(false);
      },
      onError: () => {
        void signOut({ redirect: true });
        setLoadingLogOut(false);
      },
    },
  });

  const handleClick = (path: string) => {
    if (path.includes("logout")) {
      setIsLogoutPopupOpen(true);
    } else if (!router.pathname.includes(path)) {
      void router.push(`/app/${path.toLowerCase()}`);
    }
  };

  const contact = fromOneOf(profile?.contact);

  return (
    <div className={containerClassName}>
      <HeadTitle page={page} />
      <div className="z-50 flex items-center justify-between w-full">
        <Header
          pageName={activeMenu || page}
          withBackButton={withBackButton}
          withNavigation={!onlyLogo}
          firstName={contact?.firstName || ""}
          lastName={contact?.lastName || ""}
          extendMenu={extendMenu}
          nav={HEADER_NAV_LIST}
          navMobile={HEADER_NAV_LIST_MOBILE}
          logoOnClick={() => {
            void router.push(Routes.overview);
          }}
          logoClassName="cursor-pointer"
          onBackButtonClick={() => {
            router.back();
          }}
          onClick={handleClick}
        />
        {isLogoutPopupOpen && (
          <Warning
            type="logout"
            isOpen={isLogoutPopupOpen}
            actions={[
              {
                title: "Cancel",
                buttonVariant: "outlined",
                onClick: () => {
                  setIsLogoutPopupOpen(false);
                },
              },
              {
                title: "Log Out now",
                buttonVariant: "primary",
                loading: loadingLogOut,
                onClick: () => {
                  setLoadingLogOut(true);
                  mutateSignOut.mutate();
                },
              },
            ]}
            onClose={() => {
              setIsLogoutPopupOpen(false);
            }}
          />
        )}
      </div>
      {children}
    </div>
  );
};

export default Layout;
