export type TWarningType =
  | "delete"
  | "update"
  | "cancel"
  | "repeal"
  | "session_expired"
  | "logout";

export interface WarningTextProps {
  warningType: TWarningType;
  objectName?: string;
  attributes?: string[];
  time?: string;
}

export const warningText = ({
  warningType,
  objectName,
  attributes,
  time,
}: WarningTextProps) => {
  switch (warningType) {
    case "delete": {
      if (objectName) {
        return {
          title: `Delete the ${objectName}?`,
          explainer: `Are you sure you want to delete this ${objectName}${
            attributes ? " " + attributes.join("") : ""
          }?`,
        };
      }

      break;
    }

    case "update": {
      if (objectName) {
        return {
          title: `Update the ${objectName}?`,
          explainer: `Are you sure you want to update this ${objectName}${
            attributes ? " " + attributes.join("") : ""
          }?`,
        };
      }

      break;
    }

    case "cancel": {
      if (objectName) {
        return {
          title: `Cancel the ${objectName}?`,
          explainer: `Are you sure you want to cancel this ${objectName}${
            attributes ? " " + attributes.join("") : ""
          }?`,
        };
      }

      break;
    }

    case "repeal": {
      if (objectName) {
        return {
          title: `Repeal the ${objectName}?`,
          explainer: `Are you sure you want to repeal this ${objectName}${
            attributes ? " " + attributes.join("") : ""
          }?`,
        };
      }

      break;
    }

    case "session_expired": {
      if (time) {
        return {
          title: "Your session is expiring",
          explainer: `You will be automatically signed out in ${time}.`,
        };
      }

      break;
    }

    case "logout": {
      return {
        title: "Logging out",
        explainer: "You will be returned to the login screen.",
      };
    }

    default:
      break;
  }
};

export default warningText;
