import React from "react";
import { Notice, AlertStatus, Popup } from "@fyvedev/fe-storybook";

interface Props {
  isOpen: boolean;
  title: string;
  explainer?: string;
  infoBar?: any;
  image?: React.ReactNode;
  imageContainerClassName?: string;
  actions?: any;
  onClose?: () => void;
}

const WONotice: React.FC<Props> = ({
  isOpen,
  infoBar,
  title,
  explainer,
  image,
  imageContainerClassName,
  actions,
  onClose,
}) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose}>
      <Notice
        defaultChildrenWrapper
        status={AlertStatus.INFO}
        infoBar={infoBar}
        alertHeading={title}
        className="z-50"
        image={image}
        imageContainerClassName={imageContainerClassName}
        hide={onClose}
        actions={actions}
        size="s"
      >
        {explainer}
      </Notice>
    </Popup>
  );
};

export default WONotice;
